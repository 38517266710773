@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('/templates/morc/fonts/roboto-v30-latin-300.woff2') format('woff2'),
       url('/templates/morc/fonts/roboto-v30-latin-300.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/templates/morc/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
       url('/templates/morc/fonts/roboto-v30-latin-regular.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('/templates/morc/fonts/roboto-v30-latin-italic.woff2') format('woff2'),
       url('/templates/morc/fonts/roboto-v30-latin-italic.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('/templates/morc/fonts/roboto-v30-latin-500.woff2') format('woff2'),
       url('/templates/morc/fonts/roboto-v30-latin-500.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/templates/morc/fonts/roboto-v30-latin-700.woff2') format('woff2'),
       url('/templates/morc/fonts/roboto-v30-latin-700.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('/templates/morc/fonts/roboto-v30-latin-900.woff2') format('woff2'),
       url('/templates/morc/fonts/roboto-v30-latin-900.woff') format('woff');
}